import React from 'react';
import { Link } from 'gatsby';
//

import { ButtonConstants } from './';

import './Button.scss';

export default function Button({
  label,
  type,
  link,
  ariaLabel,
  theme,
  handleClick,
  tab,
  className,
}) {
  const classes = ['Button', 'text-cta', theme];

  if (className) {
    classes.push(className);
  }

  if (type === ButtonConstants.TYPE.EXTERNAL) {
    return (
      <a
        className={classes.join(' ')}
        href={link}
        aria-label={ariaLabel}
        target={'_blank'}
        rel={'noopener noreferrer'}
        tabIndex={tab}
        onClick={() => {
          if (handleClick) handleClick();
        }}
      >
        {label}
      </a>
    );
  } else if (type === ButtonConstants.TYPE.DOWNLOAD) {
    return (
      <a
        className={classes.join(' ')}
        href={link}
        aria-label={ariaLabel}
        target={'_self'}
        download
        rel={'noopener noreferrer'}
        tabIndex={tab}
        onClick={() => {
          if (handleClick) handleClick();
        }}
      >
        {label}
      </a>
    );
  }

  return (
    <Link
      className={classes.join(' ')}
      to={link}
      aria-label={ariaLabel}
      tabIndex={tab}
      onClick={() => {
        if (handleClick) handleClick();
      }}
    >
      {label}
    </Link>
  );
}
