import React from 'react';

export default function Cell({
  children,
  className = '',
  collapse = false,
  small = 0,
  smallPush = 0,
  medium = 0,
  mediumPush = 0,
  large = 0,
  largePush = 0,
  style,
  ...rest
}) {
  let classes = ['Cell'];

  if (className) classes = classes.concat(className.split(' '));

  if (collapse) classes.push('collapse-padding');

  if (small) classes.push(`small-${small}`);

  if (smallPush) classes.push(`small-push-${smallPush}`);

  if (medium) classes.push(`medium-${medium}`);

  if (mediumPush) classes.push(`medium-push-${mediumPush}`);

  if (large) classes.push(`large-${large}`);

  if (largePush) classes.push(`large-push-${largePush}`);

  return (
    <div className={`${classes.join(' ')}`} style={style} {...rest}>
      {children}
    </div>
  );
}
