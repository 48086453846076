import React from 'react';
//

export default function Grid({ children, className, subGrid, style }) {
  let classes = ['Grid'];

  if (className) classes = classes.concat(className.split(' '));

  if (subGrid) classes.push('sub-grid');

  return (
    <div className={`${classes.join(' ')}`} style={style}>
      {children}
    </div>
  );
}
