import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { Grid, Cell } from './../grid';
import './Footer.scss';

export default function Footer({ className = '', showLegalese }) {
  const classes = ['Footer'];

  if (className) {
    classes.push(className);
  }

  return (
    <footer className={classes.join(' ')}>
      <Grid>
        <Cell className='social-icons' small={12}>
          <ul>
            <li>
              <OutboundLink
                href='https://www.facebook.com/ally'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={`/img/social/facebook.png`}
                  alt='Facebook'
                  width='24'
                  height='46'
                />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href='https://twitter.com/ally'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={`/img/social/twitter.png`}
                  alt='Twitter'
                  width='54'
                  height='44'
                />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href='https://www.youtube.com/c/ally'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={`/img/social/youtube.png`}
                  alt='YouTube'
                  width='68'
                  height='50'
                />
              </OutboundLink>
            </li>

            <li>
              <OutboundLink
                href='https://www.instagram.com/ally/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={`/img/social/instagram.png`}
                  alt='Instagram'
                  width='50'
                  height='50'
                />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href='https://www.linkedin.com/company/ally/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={`/img/social/linkedin.png`}
                  alt='LinkedIn'
                  width='52'
                  height='50'
                />
              </OutboundLink>
            </li>
          </ul>
        </Cell>
        <Grid className='legal-container'>
          <Cell className='links' collapse small={12} large={6}>
            <ul>
              <li>
                <OutboundLink
                  href='https://www.ally.com/security/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-body color-text-medium-blue'
                >
                  Security
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href='https://www.ally.com/privacy/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-body color-text-medium-blue'
                >
                  Privacy
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href='https://www.ally.com/legal/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-body color-text-medium-blue'
                >
                  Legal
                </OutboundLink>
              </li>
            </ul>
          </Cell>
          <Cell className='legal-copy' collapse small={12} large={6}>
            <div className='text-body color-text-plum'>
              © 2020 - 2021 Ally Financial Inc.
              {showLegalese ? (
                <>
                  <br />
                  <br />
                  Apple and the Apple Logo are registered trademarks of Apple
                  Inc. App Store is a registered service mark of Apple Inc.
                  <br />
                  <br />
                  Google Play and the Google Play logo are trademarks of Google
                  LLC.
                </>
              ) : null}
            </div>
          </Cell>
        </Grid>
      </Grid>
    </footer>
  );
}
