export default {
  TYPE: {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
    DOWNLOAD: 'download',
  },
  THEMES: {
    PRIMARY: 'primary',
    INVERSE: 'inverse',
  },
};
