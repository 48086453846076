import React from 'react';
import { Helmet } from 'react-helmet';
import 'focus-visible';

export default function Head({
  title = 'TITLE MISSING',
  description = 'DESCRIPTION MISSING',
  keywords = 'KEYWORDS MISSING',
  image = 'IMAGE MISSING',
  url = 'URL MISSING',
}) {
  return (
    <Helmet>
      <title>{title}</title>

      <link rel='canonical' href={url} />
      <meta property='og:url' content={url} />

      <meta itemprop='name' content={title} />
      <meta name='description' content={description} />
      <meta itemprop='description' content={description} />
      <meta name='image' content={image} />

      <meta name='keywords' content={keywords} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image:src' content={image} />
      <meta name='twitter:site' content='@Ally' />

      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />

      <meta property='og:type' content='website' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />

      <link rel='shortcut icon' href={`/favicon.ico`} />

      <link rel='stylesheet' type='text/css' href='/fonts/fonts.css' />

      <html lang='en' />
    </Helmet>
  );
}
