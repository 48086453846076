import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
//
import './Nav.scss';

import { Button, ButtonConstants } from './../button';
import { createFocusTrap } from 'focus-trap';

export default function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [onBookPage, setOnBookPage] = useState(false);

  const navElRef = useRef();
  const focusTrap = useRef();

  const handleClickMenu = () => {
    if (menuOpen) {
      focusTrap.current.deactivate();
    } else {
      focusTrap.current.activate();
    }

    setOnBookPage(window.location.pathname !== '/');
  };

  useEffect(() => {
    focusTrap.current = createFocusTrap(navElRef.current, {
      onActivate: () => {
        setMenuOpen(true);
      },
      onDeactivate: () => {
        setMenuOpen(false);
      },
      clickOutsideDeactivates: true,
    });
  }, []);

  return (
    <nav className='Nav' ref={navElRef}>
      <div className='nav-bar color-background-white'>
        <div className='logo'>
          <a
            href='https://www.ally.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={`/img/ally-logo.png`}
              alt='Ally logo, go to ally.com'
              width='158'
              height='93'
            />
          </a>
        </div>
        <button
          className='hamburger'
          aria-label={menuOpen ? 'Close menu' : 'Open menu'}
          onClick={handleClickMenu}
        >
          <svg
            className={`menu-icon ${menuOpen ? 'hide' : ''}`}
            width='35'
            height='23'
          >
            <rect width='35' height='5' fill='#650360'></rect>
            <rect y='9' width='35' height='5' fill='#650360'></rect>
            <rect y='18' width='35' height='5' fill='#650360'></rect>
          </svg>
          <svg className={`close-icon ${menuOpen ? 'show' : ''}`}>
            <line
              x1='6%'
              y1='6%'
              x2='90%'
              y2='90%'
              style={{
                stroke: '#650360',
                strokeWidth: 5,
              }}
            />
            <line
              x1='90%'
              y1='6%'
              x2='6%'
              y2='90%'
              style={{
                stroke: '#650360',
                strokeWidth: 5,
              }}
            />
          </svg>
        </button>
      </div>
      <div
        className={`menu-container color-background-white ${
          menuOpen ? 'show' : ''
        } ${!onBookPage ? 'hide-breadcrumb' : ''}`}
      >
        <div className='breadcrumb'>
          <Link to={'/'} className='text-cta'>
            <span className='back-caret'></span>Back to Adventures with Money
          </Link>
        </div>
        <div className={`menu-content ${menuOpen ? 'display' : ''}`}>
          <div className='menu-title'>
            <h1 className='text-section-headline color-text-plum'>
              Explore our <span className='color-text-grapefruit'>books.</span>
            </h1>
          </div>
          <div className='menu-items'>
            <div className='menu-item'>
              <div className='item-image'>
                <img
                  src={`/img/menu-book-planet-zeee.png`}
                  alt='The book Planet Zeee and the Money Tree'
                  width='479'
                  height='477'
                />
              </div>
              <div className='item-title'>
                <h2 className='text-eyebrow color-text-plum'>
                  Planet Zeee and the{' '}
                  <span className='no-break'>Money Tree</span>
                </h2>
                <div className='item-cta desktop'>
                  <Button
                    tab={menuOpen ? 0 : -1}
                    label={'Explore the Book'}
                    type={ButtonConstants.TYPE.INTERNAL}
                    link={'/planet-zeee-and-the-money-tree'}
                    handleClick={() => setMenuOpen(false)}
                    theme={ButtonConstants.THEMES.PRIMARY}
                    ariaLabel={'Explore Planet Zeee and the Money Tree book'}
                  />
                </div>
              </div>
              <div className='item-cta mobile'>
                <Button
                  tab={menuOpen ? 0 : -1}
                  label={'Explore the Book'}
                  type={ButtonConstants.TYPE.INTERNAL}
                  link={'/planet-zeee-and-the-money-tree'}
                  handleClick={() => setMenuOpen(false)}
                  theme={ButtonConstants.THEMES.PRIMARY}
                />
              </div>
            </div>
            <div className='menu-item'>
              <div className='item-image'>
                <img
                  src={`/img/menu-book-cosmo-phone.png`}
                  alt='The book Emma and the Cosmo Phone, new release'
                  width='479'
                  height='489'
                />
              </div>
              <div className='item-title'>
                <h2 className='text-eyebrow color-text-plum'>
                  Emma and the Cosmo Phone
                </h2>
                <div className='item-cta desktop'>
                  <Button
                    tab={menuOpen ? 0 : -1}
                    label={'Explore the Book'}
                    type={ButtonConstants.TYPE.INTERNAL}
                    link={'/emma-and-the-cosmo-phone'}
                    handleClick={() => setMenuOpen(false)}
                    theme={ButtonConstants.THEMES.PRIMARY}
                    ariaLabel={'Explore Emma and the Cosmo Phone book'}
                  />
                </div>
              </div>
              <div className='item-cta mobile'>
                <Button
                  tab={menuOpen ? 0 : -1}
                  label={'Explore the Book'}
                  type={ButtonConstants.TYPE.INTERNAL}
                  link={'/emma-and-the-cosmo-phone'}
                  handleClick={() => setMenuOpen(false)}
                  theme={ButtonConstants.THEMES.PRIMARY}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
