import React from 'react';
//

export default function GridContainer({ children, className, style }) {
  let classes = ['GridContainer'];

  if (className) classes = classes.concat(className.split(' '));

  return (
    <div className={`${classes.join(' ')}`} style={style}>
      {children}
    </div>
  );
}
